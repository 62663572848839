var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',[_c('b-card',[_c('b-card-header',[_c('b-card-title',[_vm._v(" "+_vm._s(_vm.$t('COLLABORATEURS_LIST'))+" ")])],1),(_vm.company.users)?_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.company.users,"paginate":true,"search-options":{
        enabled: false,
      },"sort-options":{
        enabled: false,
      },"pagination-options":{
        enabled: true,
        perPage: _vm.pageLength,
      }},scopedSlots:_vm._u([{key:"table-row",fn:function(data){return [(data.column.field === 'status')?_c('span',[(data.row.status === true)?_c('center',[_c('b-badge',{attrs:{"variant":"light-success"}},[_vm._v(" "+_vm._s(_vm.$t('COLLABORATEUR_ACTIVE'))+" ")])],1):(data.row.status === false)?_c('center',[_c('b-badge',{attrs:{"variant":"light-danger"}},[_vm._v(" "+_vm._s(_vm.$t('COLLABORATEUR_NON_ACTIVE'))+" ")])],1):_vm._e()],1):_vm._e()]}},{key:"table-column",fn:function(props){return [_vm._v(" "+_vm._s(_vm.$t(props.column.label))+" ")]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('PAGINATION_SHOWING'))+" ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['5','10','25', '50', '100']},on:{"input":function (value){ return props.perPageChanged({currentPerPage:value}); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('PAGINATION_SHOWING_ELEMENT'))+" "+_vm._s(_vm.$t('PAGINATION_TOTAL'))+" "+_vm._s(props.total)+" ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value){ return props.pageChanged({currentPage:value}); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}],null,false,4271141924)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }