<template>
  <b-overlay>
    <b-card>
      <b-card-header>
        <b-card-title>
          {{ $t('COLLABORATEURS_LIST') }}
        </b-card-title>
      </b-card-header>
      <vue-good-table
        v-if="company.users"
        :columns="columns"
        :rows="company.users"
        :paginate="true"
        :search-options="{
          enabled: false,
        }"
        :sort-options="{
          enabled: false,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template
          slot="table-row"
          slot-scope="data"
        >
          <span v-if="data.column.field === 'status'">
            <center v-if="data.row.status === true">
              <b-badge
                variant="light-success"
              >
                {{ $t('COLLABORATEUR_ACTIVE') }}
              </b-badge>
            </center>
            <center v-else-if="data.row.status === false">
              <b-badge
                variant="light-danger"
              >
                {{ $t('COLLABORATEUR_NON_ACTIVE') }}
              </b-badge>
            </center>

          </span>
        </template>

        <template
          slot="table-column"
          slot-scope="props"
        >
          {{ $t(props.column.label) }}
        </template>

        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                {{ $t('PAGINATION_SHOWING') }}
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['5','10','25', '50', '100']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> {{ $t('PAGINATION_SHOWING_ELEMENT') }} {{ $t('PAGINATION_TOTAL') }} {{ props.total }} </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </b-overlay>

</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import {
  BOverlay, BCard, BCardHeader, BCardTitle, BFormSelect, BPagination, BBadge,
} from 'bootstrap-vue'
import 'vue-good-table/dist/vue-good-table.css'

export default {
  components: {
    VueGoodTable,
    BOverlay,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormSelect,
    BPagination,
    BBadge,
  },
  props: {
    title: {
      type: String,
      default: () => '',
    },
    company: {
      type: Object,
      default: () => [],
    },
  },
  data() {
    return {
      columns: [
        {
          label: 'COMPANY_COLLABORATEURS_FIRSTNAME',
          field: 'firstName',
          filterable: true,
        },
        {
          label: 'COMPANY_COLLABORATEURS_LASTNAME',
          field: 'lastName',
          filterable: true,
        },
        {
          label: 'COMPANY_COLLABORATEURS_PHONE',
          field: 'phone',
          filterable: true,
        },
        {
          label: 'COMPANY_COLLABORATEURS_STATUS',
          field: 'status',
          filterable: true,
        },
      ],
      filter: {
        selectedCompany: ' ',
      },
      pageLength: 50,
      userId: null,

    }
  },
  methods: {
    setStatut(statut) {
      let returnStatut = 'danger'
      // eslint-disable-next-line default-case
      switch (statut) {
        case true:
          returnStatut = 'success'
          break
        case false:
          returnStatut = 'danger'
          break
      }
      return returnStatut
    },
  },
}
</script>
<style scoped lang="scss">
body.dark-layout .vgt-wrap .vgt-table td span.badge {
  color: #fff;
}
.user-active{
  color:red
}
.card-header{
  padding-left:0;
}
</style>
