<template>
  <b-overlay
    :show="showOverlay"
    rounded="xl"
  >
    <b-card>
      <b-card-header>
        <b-card-title>
          {{ company.name }}
        </b-card-title>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="btn-icon"
          @click="$router.push({ name: 'companyListing' })"
        >
          <feather-icon
            icon="RewindIcon"
          />
          {{ $t('BUTTON_BACK') }}
        </b-button>
      </b-card-header>
    </b-card>

    <b-card
      class="card-statistics"
    >
      <b-card-body class="statistics-body">
        <b-row>
          <b-col
            md="3"
            sm="3"
            class="mb-2 mb-md-0"
          >
            <b-media no-body>
              <b-media-aside
                class="mr-2"
              >
                <b-avatar
                  size="48"
                  variant="light-info"
                >
                  <feather-icon
                    size="24"
                    icon="MailIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ company.emailContact }}

                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ $t("COMPANY_EMAIL_CONTACT") }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>

          <b-col
            md="3"
            sm="3"
            class="mb-2 mb-md-0"
          >
            <b-media no-body>
              <b-media-aside
                class="mr-2"
              >
                <b-avatar
                  size="48"
                  variant="light-info"
                >
                  <feather-icon
                    size="24"
                    icon="HomeIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <b-card-text class="font-small-3 mb-0">
                  {{ company.adress }} {{ company.adress2 }} <br>{{ company.postalCode }} {{ company.city }} <br>{{ company.pays.name }}
                </b-card-text>

              </b-media-body>
            </b-media>
          </b-col>

          <b-col
            md="3"
            sm="3"
            class="mb-2 mb-md-0"
          >
            <b-media no-body>
              <b-media-aside
                class="mr-2"
              >
                <b-avatar
                  size="48"
                  variant="light-info"
                >
                  <feather-icon
                    size="24"
                    icon="PhoneCallIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ company.phone }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ $t("COMPANY_PHONE") }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>

          <b-col
            md="3"
            sm="3"
            class="mb-2 mb-md-0"
          >
            <b-media no-body>
              <b-media-aside
                class="mr-2"
              >
                <b-avatar
                  size="48"
                  variant="light-info"
                >
                  <feather-icon
                    size="24"
                    icon="CheckSquareIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ company.navisionClientCode }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ $t("COMPANY_CODE_CLIENT_NAVISION") }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card>
      <collaborateurs :company="company" />
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardText,
  BAvatar,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import collaborateurs from './collaborateurs.vue'

export default {
  components: {
    BOverlay,
    BCard,
    BMedia,
    BMediaBody,
    BMediaAside,
    BCol,
    BCardBody,
    BRow,
    BCardHeader,
    BCardTitle,
    BCardText,
    BAvatar,
    collaborateurs,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      idCompany: '',
      company: {},
      showOverlay: true,
    }
  },
  mounted() {
    if (this.$route.params.id && Number.isInteger(parseInt(this.$route.params.id, 10))) {
      this.idCompany = this.$route.params.id
      this.$store.dispatch('companies/fetchOne', this.$route.params.id)
        .then(r => {
          this.company = r
          this.showOverlay = false
        }).catch(() => {
          this.$router.push('/pages/error-404')
        })
    } else {
      this.$router.push('/pages/error-404')
    }
  },
}
</script>

<style>
</style>
